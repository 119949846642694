<template>
  <div id="user-list">
    <!-- app drawer -->
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <configuration-form
        v-model="item"
        :is-form-active="isFormActive"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un configuration') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
      </configuration-form>
    </v-dialog>
    <!-- configuration total card -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Configurations") }}
      </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('Rechercher')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="openDialog({type: 'text',loading: false, value: null })"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t("Ajouter un Configuration") }}</span>
          </v-btn>
          <!--          <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>-->
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        :headers="tableColumns"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-footer
        show-select
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        @page-count="pageCount = $event"
      >
        <!-- actions -->
        <template #[`item.key`]="{item}">
          <v-text-field
            v-model="item.key"
            outlined
            dense
            :hide-details="true"
          />
        </template>
        <template #[`item.type`]="{item}">
          <v-autocomplete
            v-model="item.type"
            outlined=""
            dense
            :hide-details="true"
            :label="$t('Type')"
            :items="['text','long_text','file','image']"
          />
        </template>
        <template #[`item.value`]="{item}">
          <v-text-field
            v-if="item.type=='text'"
            v-model="item.value"
            outlined=""
            dense
            :hide-details="true"
            :label="$t('Value')"
          />
          <v-textarea
            v-if="item.type=='long_text'"
            v-model="item.value"
            outlined=""
            dense
            :hide-details="true"
            :label="$t('Value')"
          />
          <file-upload
            v-if="item.type=='file'"
            v-model="item.value"
            :label="$t('Choose file')"

            :path="item.value"
            :folder="item.key+'/'"
            :btn="false"
            :show-download="true"
            :extra="false"
            :hide-label="false"
            inputname="file"
            accept="*"
          >
            <template #input>
              <v-btn color="primary" small>
                {{ $t("Télécharger") }}
              </v-btn>
            </template>
          </file-upload>
          <v-row v-if="item.type=='image'">
            <v-col class="shrink">
              <file-upload
                v-model="item.value"
                add-class="mx-auto justify-center"
                :label="$t('Choose photo')"
                :folder="item.key+'/'"
                :btn="false"
                :extra="false"
                :hide-label="false"
                :icon="true"
                :show-download="false"
                :filter="false"
                icon-color="grey"
                :icon-name="icons.mdiImage"
                inputname="file"
                accept="image/*"
              />
            </v-col>
            <v-col class="shrink">
              <v-img
                :src="item.value"
                :max-width="50"
                cover
              />
            </v-col>
          </v-row>
        </template>
        <template #[`item.actions`]="{item}">
          <v-btn
            icon
            small
            :loading="item.loading"
          >
            <v-icon
              size="20"
              @click="onSubmit(item)"
            >
              {{ icons.mdiContentSaveOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="error"
            :loading="item.loading"
          >
            <v-icon
              size="20"
              color="error"
              @click="confirmDelete(item)"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiContentSaveOutline,
  mdiImage,
} from '@mdi/js'
import {
  computed, ref, watch, onUnmounted,
} from '@vue/composition-api'
import axios from '@axios'

// sidebar
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import userStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import FileUpload from '@/components/file_upload'

export default {
  components: {
    FileUpload,
    RowsPerPage,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'configuration'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      onSubmit,
      confirmDelete,
      deleteDialog,
      fetchItems,
    } = controller()

    return {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      mdiContentSaveOutline,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deleteDialog,
      confirmDelete,
      openDialog,
      deleteItem,
      avatarText,
      fetchItems,
      onSubmit,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiContentSaveOutline,
        mdiImage
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
